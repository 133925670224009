.navbar-header {
  margin-top: 20px;
}

.button-columns {
  color: #212529;
  text-decoration: none;
  background-color: rgb(230, 205, 174);
  border: 3px solid transparent;
  max-width: 330px;
  height: 350px;
  transition: border 0.2s ease-in-out;
}

.button-columns:hover {
  color: #212529;
  border-color: #aaaaaa;
  background-color: rgb(221, 193, 157);
}

.button-logo {
  max-width: 170px;
}

.section-separator {
  background-color: #d1898a;
  height: 10px;
  width: 100px;
  border-radius: 25px;
  margin: 2rem auto;
}

@media screen and (max-width: 767px) {
  .fs-2.fw-bold.text-center.w-100 {
    margin-bottom: 30px;
  }
}

.btn-text {
  max-width: 330px;
}

.w-100 {
  justify-content: center;
}

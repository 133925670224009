.navbar-custom {
  padding: 0;
}

.nav-logo-container {
  border-radius: 50%;
  padding: 0.5rem;
  z-index: 10;
}

.custom-nav-pad {
  padding: 0.5rem;
}

.nav-logo-container img {
  width: 64px;
  height: 64px;
  text-align: center;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
}

.rounded-navbar-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  min-width: 8rem;
  height: 2.2rem;
  margin: 0 0.5rem;
  border-radius: 1rem;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
  transition: background-color ease-in-out, border linear;
  transition-duration: 50ms;
  background-color: #fafcfd;
  border: 2px solid transparent;
}

.rounded-navbar-button a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  color: #121212;
}

.rounded-navbar-button:hover {
  background-color: #f6fafd;
  border: 2px solid #cfcfcf;
}

.rounded-navbar-button.no-hover:hover {
  background-color: #fafcfd;
  border-color: transparent;
}

.profile-nav-button {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 2.2rem;
  width: 12rem;
  border-radius: 1rem;
  color: black;
  background-color: #fafcfd;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.2);
}

.profile-button {
  display: flex;
  align-items: center;
  gap: 5px;
  /* font-size: 22px; */
  transition-duration: 300ms;
}

.profile-button:hover {
  color: rgb(234, 169, 41);
  transition-duration: 300ms;
}

.logout {
  color: rgb(255, 136, 0);
}

/* Mobile Nav Styles */
.mobile-nav-button {
  filter: drop-shadow(0 0 2px gray);
  position: relative;
  width: 40px;
  height: 40px;
}

.mobile-nav-bar,
.mobile-nav-active .mobile-nav-bar {
  display: block;
  width: 100%;
  /* Offset top of the other bars by half of height if this value changes */
  height: 4px;
  position: absolute;
  top: calc(50% - 2px);
  left: 0;
  background-color: white;
  margin: auto 0;
  border-radius: 2px;
  backdrop-filter: drop-shadow(5px 5px white);
  transition: top 0.25s, transform 0.25s, opacity 0.25s;
}

.mobile-nav-top-bar {
  top: 10%;
}

.mobile-nav-center-bar {
  /* Nothing to change about the center bar */
}

.mobile-nav-bottom-bar {
  top: calc(90% - 4px);
}

.mobile-nav-active .mobile-nav-top-bar {
  transform: rotate(45deg);
}

.mobile-nav-active .mobile-nav-center-bar {
  opacity: 0;
}

.mobile-nav-active .mobile-nav-bottom-bar {
  transform: rotate(-45deg);
}

.mobile-navbar-ul {
  z-index: 100;
  top: 100%;
  padding: 0;
  background-color: white;
  border-style: solid;
  border-color: gray;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 4px 4px;
  /* Hiding and showing */
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.25s;
}

.mobile-navbar-ul li,
.mobile-navbar-ul li > * {
  display: block;
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: black;
  text-decoration: none;
  padding: 3px 0;
}

.mobile-navbar-ul li:hover {
  background-color: #fed9a4;
}

.mobile-nav-active .mobile-navbar-ul {
  transform: scaleY(1);
}

@media only screen and (max-width: 767px) {
  .nav-logo-container {
    /* Using relative position instead of padding due to 0 width nav */
    position: relative;
    left: 0.5rem;
    width: 0;
    border-radius: 50%;
    padding: 0.5rem 0;
  }
}

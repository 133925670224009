.white-app-frame-container {
  width: 100%;
  min-height: 77.5vh; /* Arbitrary */
  background-color: white;
  border-radius: 25px;
  margin: 0.5rem 0 0;
  z-index: 0;
  transition: width 0.25s linear;
}

.logo-container {
  display: flex;
  justify-content: center;
  top: -50px;
  margin: 0 auto;
  border-radius: 50%;
}

.logo-container img {
  width: 120px;
  height: 120px;
  text-align: center;
  background-color: white;
  border-radius: 50%;
}

.logo-container img:hover {
  cursor: pointer;
}

.app-frame-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.app-frame-main > * {
  margin-left: 3rem;
  margin-right: 3rem;
}

.feedback-div {
  display: flex;
  justify-content: center;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .white-app-frame-container {
    width: 90%;
  }
}

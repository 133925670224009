.order-fulfill-table {
  /* Strange table sizing CSS */
  height: 1px;
}

.order-fulfill-table-header {
  background-color: #ffe9cc;
}

.order-fulfill-table-column-fit {
  /* Strange way to make table columns fit to content */
  width: 1px;
  white-space: nowrap;
  vertical-align: center;
}

.items-on-hold {
  display: flex;
  align-items: center;
  background-color: #0d6efd;
  padding: 5px 10px;
  border-radius: 8px;
  width: 100%;
  color: white;
}

button.sort-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  width: fit-content;
}

.align-right {
  text-align: right;
}

td.align-right {
  vertical-align: middle;
  text-align: right;
}

.order-fulfill-table-fulfill-label::before {
  content: "Fulfilled";
}

@media only screen and (max-width: 575px) {
  .order-fulfill-table-fulfill-label {
    max-width: 3.5rem;
  }

  .order-fulfill-table-fulfill-label::before {
    content: "Done";
  }
}

.order-fulfilled-row td {
  background-color: #96cab1;
}

.order-fulfill-table-entry-fulfilled-label {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  padding: 10%;
  border-left: 1px solid #0000000f;
  vertical-align: middle;
}

.order-fulfill-table-entry-fulfilled-label input {
  flex-grow: 1;
  height: 1.5rem;
}

.order-fulfill-entry-time {
  white-space: pre-wrap;
  border-left-width: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-left-color: #0000000f;
  border-right-color: #0000000f;
}

@media only screen and (min-width: 768px) {
  .order-fulfill-entry-time {
    white-space: nowrap;
  }
}

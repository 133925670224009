.authwall-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  padding: 3rem;
}

.authwall-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem;
}

.authwall-image img {
  max-width: 100%;
  max-height: 15rem;
}

.authwall-text {
  background-color: rgba(255, 232, 222, 0.93);
  padding: 2rem;
  border-radius: 0.5rem;
}

.authwall-title {
  margin-bottom: 1rem;
}

.authwall-text,
.authwall-desc {
  font-size: 1.2rem;
  font-weight: normal;
}

.authwall-subtitle {
  font-size: 0.8rem;
  font-weight: normal;
  color: #666666;
}

.authwall-login-continue-container {
  display: block;
  width: max-content;
  margin: 2rem 0 0.25rem 0;
  text-decoration: none;
  color: #1b1b1b;
}

.authwall-return-home-container {
  color: #999090;
  margin: 0 4px;
  text-decoration: underline;
  font-size: 0.8rem;
}

.authwall-return-home-container:hover {
  color: #777070;
}

.authwall-login-continue-animated {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #f7f7f7;
  border: 1px solid #1b1b1b;
  border-radius: 0.2rem;
  transition: 0.1s top, 0.1s left, 0.1s box-shadow;
}

.authwall-login-continue-container:hover .authwall-login-continue-animated {
  color: #1b1b1b;
  position: relative;
  top: -2px;
  left: 2px;
  box-shadow: -5px 5px 5px #a67b5833;
}

@media only screen and (min-width: 992px) {
  .authwall-container {
    flex-direction: row;
  }

  .authwall-image {
    width: 30%;
  }
}

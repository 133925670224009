.E404-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  padding: 3rem;
}

.E404-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2rem;
}

.E404-image img {
  max-width: 100%;
  max-height: 15rem;
}

.E404-text {
  background-color: rgba(255, 232, 222, 0.93);
  padding: 2rem;
  border-radius: 0.5rem;
}

.E404-title {
  margin-bottom: 1rem;
}

.E404-text,
.E404-desc {
  font-size: 1.2rem;
  font-weight: normal;
}

.E404-subtitle {
  font-size: 0.8rem;
  font-weight: normal;
  color: #999999;
}

.E404-return-home-container {
  display: inline-block;
  margin: 2rem 0 0.25rem 0;
  text-decoration: none;
  color: #1b1b1b;
}

.E404-return-home-animated {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #f7f7f7;
  border: 1px solid #1b1b1b;
  border-radius: 0.2rem;
  transition: 0.1s top, 0.1s left, 0.1s box-shadow;
}

.E404-return-home-container:hover .E404-return-home-animated {
  color: #1b1b1b;
  position: relative;
  top: -2px;
  left: 2px;
  box-shadow: -5px 5px 5px #a67b5833;
}

@media only screen and (min-width: 992px) {
  .E404-container {
    flex-direction: row;
  }

  .E404-image {
    width: 30%;
  }
}

.staff_calendar .react-datepicker {
  font-size: var(--fs-400);
}

.staff_timepicker .react-datepicker {
  font-size: 0.85rem;
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.containers
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.header {
    background-color: #f0f0f0;
    padding: 10px;
    margin: 0px;
    border-bottom: 1px solid #cfcfcf;
    border-radius: 10px 10px 0 0;
}

.item-info
{
    outline: 1px solid #cfcfcf;
    border-radius: 10px;
    height: 375px;
    margin-left: 150px;
    margin-top: 10px;
}

.item-info-content
{
    margin: 5px;
}

.item-details
{
    outline: 1px solid #cfcfcf;
    border-radius: 10px;
    margin-right: 150px;
    margin-top: 10px;
}

.item-details-content
{
    margin: 5px;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

@media (max-width: 768px) {
    .containers {
        flex-direction: column; /* Stack containers on smaller screens */
    }

    .item-info {
        margin-left: 0px;
        margin-bottom: 10px;
    }

    .item-details{
        margin-right: 0px;
    }
}
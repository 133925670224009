/* Uncomment sticky to keep controls on the right. Unsure if we want that behavior */
.quantity-controls {
  /*position: sticky;*/
  right: 0;
  z-index: 10;
  box-shadow: none;
}

.quantity-controls > * {
  opacity: 1 !important;
}

.cart-quantity {
  font-size: 20px;
  min-width: 2em;
}

@media screen and (max-width: 540px) {
  .quantity-controls {
    display: flex;
    flex-direction: column;
  }

  .quantity-increment {
    order: 1;
  }
  .cart-quantity {
    order: 2;
  }
  .quantity-decrement {
    order: 3;
  }
}

.item-name {
  font-size: 18px;
}

.category-container {
  display: flex;
  gap: 1rem;
}

.overlay {
  z-index: 10;
}

.reuse-bag {
  color: #d1898a;
}

.search-container {
  display: flex;
  gap: 1rem;
  margin-top: 0.15rem;
  justify-content: flex-end;
}

.order-info {
  text-align: center;
  margin-bottom: 2.5rem;
  margin-top: -2rem;
}

.food-bag {
  color: var(--pantry-secondary);
}

.category-search-container {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
}

#validation-text {
  margin-top: 0.5rem;
}

.bottom-interact-container {
  display: flex;
  justify-content: space-between;
}

.pagination-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.arrow-container {
  display: flex;
  gap: 20px;
}

.product {
  inline-size: 500px;
}

.table-container {
  overflow: auto;
}

.quantity {
  font-size: 20px;
  min-width: 2em;
}

#allergen-modal {
  display: flex;
  justify-content: flex-start;
}

.allergen-key-i {
  font-style: italic;
  cursor: pointer;
  color: #d1898a;
  transition-duration: 300ms;
}

.allergen-key-i:hover {
  color: #fb513a;
}

#legend-img {
  width: 100%;
  margin-top: 2rem;
}

#allergen-modal-order {
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
}

/*RESPONSIVENESS*/
@media only screen and (max-width: 1440px) {
  #legend-img {
    width: 60%; 
  }

  #allergen-modal-order {
    max-width: 40%; 
  } 
}

@media only screen and (max-width: 1024px) {
  #legend-img {
    width: 80%; 
  }

  #allergen-modal-order {
    max-width: 40%; 
  } 
}

@media only screen and (max-width: 864px) {
  .category-search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .category-container {
    display: flex;
    flex-direction: column;
  }

  .order-top-interact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
    gap: 8px;
  }

  .order-info {
    margin-top: 2rem;
  }

  .order-info {
    margin-top: 0;
  }

  .search-container {
    margin-top: 1rem;
  }

  .arrow-container {
    margin-top: 1.5rem;
  }

  #legend-img {
    width: 80%; 
  }

  #allergen-modal-order {
    max-width: 50%; 
  } 
}

@media only screen and (max-width: 558px) {
  .bottom-interact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .pagination-container {
    margin-top: 1rem;
  }

  #empty-cart {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #legend-img {
    width: 90%; 
  }

  #allergen-modal-order {
    max-width: 75%; 
  }
}

@media only screen and (max-width: 430px) {
  #allergen-modal {
    margin-left: 0;
  }

  #legend-img {
    width: 95%; 
  }

  #allergen-modal-order {
    max-width: 85%; 
  }
}

@media only screen and (max-width: 375px) {
  .table-container {
    display: inline-block;
    margin-left: -0.1rem;
    /*max-width: 100%;*/
  }
}

@media only screen and (max-width: 360px) {
  .table-container {
    display: unset;
    margin-left: -0.5rem;
    max-width: 350px;
  }

  .item-name {
    font-size: 15px;
  }

  #legend-img {
    width: 115%; 
  }

  #allergen-modal-order {
    max-width: 80%; 
  }
}

@media only screen and (max-width: 280px) {
  .table-container {
    display: inline-block;
    overflow-x: auto;
    max-width: 250px;
  }

  #legend-img {
    width: 120%; 
  }

  #allergen-modal-order {
    max-width: 80%; 
  }
}

@media only screen and (min-height: 1000px) {
  #allergen-modal-order {
    max-height: 33vh; 
    transform: translate(-50%, -50%) translateY(-50%) !important;
  }
}

/************* CART */
div.item-container {
  display: flex;
  gap: 3rem;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  width: 35rem;
}

div.item-container > div {
  display: flex;
  gap: 0.5rem;
}

ol.order-list {
  overflow-y: auto;
}

button.sort-button {
  background-color: #fb513a;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  width: fit-content;
  white-space: nowrap;
}
/* Form page */
.form-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-inputs > label {
  display: flex;
  gap: 20px;
}

.time-picker .react-datepicker {
  font-size: 0.85rem;
}

button.sort-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  width: fit-content;
  white-space: nowrap;
}

body.abbyrl-fools {
  cursor: url("https://asucd-pantry.sfo3.cdn.digitaloceanspaces.com/ABBBBBYYYYYYYYYS.jpg"),
    auto;
  background-image: url("https://asucd-pantry.sfo3.cdn.digitaloceanspaces.com/ABBBBBYYYYYYYYY.jpeg");
  background-size: 120px 120px;
  background-attachment: fixed;
  background-repeat: repeat;
}

.abbyrl-fools .white-app-frame-container {
  background-image: url("https://asucd-pantry.sfo3.cdn.digitaloceanspaces.com/ABBBBBYYYYYYYYY.jpeg");
  background-size: 180px 180px;
  background-attachment: fixed;
  background-repeat: repeat;
  filter: brightness(1.5);
  border: 2px solid lightgray;
}

.abbyrl-fools .white-app-frame-container > * {
  filter: brightness(calc(1 / 1.5));
  background: #ffffffcc;
}

.abbyrl-fools .feedback-div {
  color: darkorange;
  text-shadow: #1b1b1b 2px 0 2px;
}

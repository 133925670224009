/* Colors */
:root {
  --pantry-primary: #fb513a;
  --pantry-secondary: #f9a935;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  background-size: cover;
  background: #eabca5; /* Fallback for older browsers */
  background: linear-gradient(120deg, #fed9a4, #da978f, #d1898a);
  margin: 0;
  font-family: "Rubik", sans-serif;
  color: #121212;
}

#root {
  position: relative;
  overflow: auto;
}

/* Custom base styles for certain elements */
tr:nth-child(even) {
  background-color: rgb(239, 239, 239);
}

thead {
  background-color: rgba(252, 153, 32, 0.2);
}

label {
  display: block;
  margin-top: 10px;
}

.w-0 {
  /* Something bootstrap is oddly missing. Using it a lot on tables */
  width: 0 !important;
}

.cursor-pointer {
  /* Class that turns the cursor into the point you would expect on something clickable */
  cursor: pointer !important;
}

:root {
  --fs-400: 1rem;
  --fs-500: 1.25rem;
  --fs-600: 1.5rem;
  --fs-lg: clamp(0.5rem, 0.5vw + 1rem, 2rem);
  --fs-xl: clamp(2rem, 1.75vw + 1rem, 8rem);
}

.btn-outline-lightPink {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d3d3d3;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: rgb(206, 145, 155);
  --bs-btn-active-bg: rgb(206, 145, 155);
  --bs-btn-active-border-color: rgb(206, 145, 155);
  --bs-btn-active-shadow: inset 0 3px 5 pxrgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgb(206, 145, 155);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgb(206, 145, 155);
  --bs-gradient: none;
}

.btn-outline-lightPink:focus {
  background: rgb(206, 145, 155);
}

.btn-lightPink {
  --bs-btn-color: #fff;
  --bs-btn-bg: rgb(206, 145, 155);
  --bs-btn-border-color: rgb(206, 145, 155);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(206, 145, 155);
  --bs-btn-hover-border-color: rgb(206, 145, 155);
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(206, 145, 155);
  --bs-btn-active-border-color: rgb(206, 145, 155);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-lightBlue {
  --bs-btn-color: #212529;
  --bs-btn-bg: #919bce;
  --bs-btn-border-color: #919bce;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #808bc2;
  --bs-btn-hover-border-color: #808bc2;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #919bce;
  --bs-btn-active-border-color: #919bce;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

@media (max-width: 1000px) {
  :root {
    --fs-500: 1.1rem;
    --fs-600: 1.25rem;
  }

  .row__container {
    flex-direction: column;
  }

  .calendar-col {
    margin-bottom: 25px;
  }
}

@media (max-width: 450px) {
  :root {
    --fs-500: 0.9rem;
    --fs-600: 1.15rem;
    --fs-lg: clamp(0.25rem, 0.75vw + 1rem, 1rem);
    --fs-xl: clamp(0.25rem, 1vw + 1rem, 4rem);
  }
}

@media (max-width: 350px) {
  :root {
    --fs-500: 0.75rem;
    --fs-600: 1rem;
    --fs-lg: 0.75rem;
  }
}

@media (max-width: 300px) {
  :root {
    --fs-500: 0.5rem;
    --fs-600: 0.75rem;
    --fs-lg: 0.5rem;
  }
}

.react-datepicker {
  font-size: var(--fs-lg);
}

.react-datepicker__header {
  padding-top: 0.8em;
}

.react-datepicker__month {
  margin: 0.4em 1em;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}

.react-datepicker__day--selected {
  background: rgb(206, 145, 155) !important;
}

.react-datepicker__current-month {
  font-size: 1em;
}

.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}

.main-heading {
  font-size: var(--fs-xl);
}

.main-subheading {
  font-size: var(--fs-500);
}

.timeslot-col__heading {
  font-size: var(--fs-500);
}

.timeslot-col__subheading {
  font-size: var(--fs-600);
}

.date-string.timeslot-col__subheading {
  color: rgb(206, 145, 155);
  font-weight: 600;
}

.product-image-section {
  min-width: 250px;
}

.product-image {
  border-radius: 1rem;
  width: 120px;
  height: 120px;
  object-fit: cover;
  object-position: center;
  user-select: none;
  display: inline-block;
  text-align: right;
}

.info-icon {
  cursor: pointer;
  transition-duration: 300ms;
}

.bold {
  font-weight: 700;
}

.allergen,
.description {
  display: flex;
  gap: 5px;
}

.allergen-icon {
  width: 20px;
}

/* .item-div {
  display: flex;
  flex-direction: row;
  align-items: center;
} */

/*RESPONSIVENESS*/
@media only screen and (max-width: 414px) {
  .product-image {
    width: 100px;
    height: 100px;
  }

  .item-modal {
    /*background-color: blue;*/
  }

  /*.test{
    display: inline;
    flex-direction: row;
  }*/
  .item-div {
    word-wrap: break-word;
    flex-direction: column;
    word-break: break-all;
  }

  .all-allergen-imgs {
    margin-top: -1rem;
    margin-bottom: 1rem;
  }

  .allergen-icon {
    width: 20px;
  }

  .allergen {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

/*
.item-modal {
  background-color: aqua;
}

.item-div {
  background-color: blue;
}*/

.category-box {
  display: flex;
  gap: 10px;
  align-items: center;
}

.text-align {
  text-align: left;
  border: 0;
  width: 70px;
}

button.sort-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  width: fit-content;
}

.p-reset {
  margin-bottom: 0rem;
}

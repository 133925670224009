.btn-pantry {
  background-color: rgba(246, 153, 14, 0.505);
  font-size: 1.15rem;
  border-radius: 10px;
  border: none;
  padding: 5px 40px;
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  transition-duration: 300ms;
  color: #121212;
}

.btn-pantry:hover {
  background-color: #ffc900;
  transform: scale(1.05);
  transition-duration: 300ms;
}

div.staff-order-search-container {
  display: flex;
  justify-content: space-between;
}

span.staff-order-search-box {
  gap: 1rem;
}

span.staff-order-search-box > p {
  margin-bottom: 0;
}

select.form-order {
  width: 200px;
}

div.order-drop-down-group {
  display: flex;
  justify-content: center;
}

table.order-table-container {
  width: 300px;
  height: 300px;
}

div.order-outer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button.sort-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  width: fit-content;
}

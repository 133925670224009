.page-number-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.active-page,
.not-active-page {
  cursor: pointer;
  user-select: none;
}

.active-page {
  font-weight: 700;
  color: #fb513a;
  font-size: 24px;
}

.not-active-page {
  opacity: 0.5;
  cursor: pointer;
  font-size: 24px;
}
